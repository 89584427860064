import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import constants from "./plugins/Constants";
import moment from "moment";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.$constants = constants;
Vue.prototype.moment = moment;

// Dynamically set the X-Parent-Origin header for all requests
axios.interceptors.request.use(
	(config) => {
		// Determine the parent origin
		const parentOrigin =
			window.location.ancestorOrigins?.[0] || (document.referrer ? new URL(document.referrer).origin : null);

		if (parentOrigin) {
			// Attach the X-Parent-Origin header
			config.headers["X-Parent-Origin"] = parentOrigin;
		}

		return config;
	},
	(error) => {
		// Handle request errors
		return Promise.reject(error);
	}
);

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
