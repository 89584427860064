<template>
	<v-app id="app">
		<v-main>
			<div :class="isPublic ? 'public-layout' : 'auth-layout'">
				<SystemBanner />
				<div
					class="navigation"
					v-if="$route.path !== '/cross-reference' && $route.path !== '/vendor-search'"
					ref="navigation"
				>
					<Navigation
						@navHeight="
							($event) => {
								transformNav($event);
							}
						"
					/>
				</div>
				<v-container
					v-if="$route.path !== '/cross-reference'"
					:class="isPublic ? 'app-content' : 'app-content px-5 mt-4'"
					:style="{ paddingTop: paddingTop }"
				>
					<keep-alive max="5" include="Mail">
						<router-view :key="$route.path"></router-view>
					</keep-alive>
				</v-container>
				<div
					v-else
					:class="isPublic ? 'app-content' : 'app-content cross-ref px-5 mt-4'"
					:style="{ paddingTop: paddingTop }"
				>
					<keep-alive max="5" include="Mail">
						<router-view :key="$route.path"></router-view>
					</keep-alive>
				</div>
			</div>
		</v-main>
	</v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import SystemBanner from "@/components/SystemBanner.vue";

export default {
	components: {
		Navigation,
		SystemBanner,
	},
	data() {
		return {
			paddingTop: "0px",
			isPartnerDomain: false,
		};
	},
	methods: {
		transformNav(paddingTop) {
			if (!this.isPartnerDomain) {
				this.paddingTop = paddingTop;
			} else {
				this.paddingTop = "0px";
			}
		},
	},
	computed: {
		isPublic() {
			return (
				this.$route.path === "/login" ||
				this.$route.path === "/request" ||
				this.$route.path === "/contact" ||
				this.$route.path === "/tutorial"
			);
		},
	},
	async beforeCreate() {
		if (document.referrer) {
			const parentOrigin =
				window.location.ancestorOrigins?.[0] ||
				(document.referrer ? new URL(document.referrer).origin : null);

			if (parentOrigin) {
				document.cookie = `parentOrigin=${encodeURIComponent(parentOrigin)};path=/`;
				try {
					const res = await this.$http.post("/api/auth/validate-iframe-origin", {
						referrer: parentOrigin,
					});
					if (res.data && res.data.vendor) {
						this.isPartnerDomain = true;
						this.transformNav("0px");
					}
				} catch (err) {
					console.error("Error validating parent domain:", err);
				}
			}
		}

		if (!this.$store.getters.isLoggedIn && window.self !== window.top) {
			this.$store
				.dispatch("login", {
					username: "n/a",
					password: "n/a",
				})
				.then(() => {
					this.$router.push("/product-search");
				});
		}
	},
};
</script>

<style lang="scss" scoped>
.v-main {
	padding: 0 !important;
	.container {
		padding-bottom: 0 !important;
	}
}

.public-layout {
	width: -webkit-fill-available;
	display: flex;
	justify-content: center;
}

.auth-layout {
	width: -webkit-fill-available;
	display: flex;
	justify-content: flex-start;
}
</style>

<!-- GLOBAL STYLES -->
<style lang="scss">
@import "./sass/main.scss";

.navigation {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
}

.app-content {
	transition: padding-top 0.3s;
	padding-left: 0 !important;
	padding-right: 0 !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	margin-top: 0 !important;
}
</style>
